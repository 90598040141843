import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import "./Footer.css"

import Capitalize from "../../utils/Capitalize"

const Footer = ({ links }) => {
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "websiteFooter"}}) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                contactInfo {
                  phoneNumber
                  emailAddress
                }
                vatText
              }
            }
          }
        }
      }
    }
  `)
  const data = query.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <footer className="Footer">
      <div className="Tables">
        <div className="Table1">
          <h3>Links</h3>
          <ul>
            {links.map(l => (
              <li key={l.link}>
                <Link className="Link" to={l.link}>
                  {Capitalize(l.name.toLowerCase())}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="Table2">
          <h3>Contact</h3>
          <ul>
            <li>{data.contactInfo.emailAddress}</li>
            <li>{data.contactInfo.phoneNumber}</li>
          </ul>
        </div>
      </div>
      <div className="CreditsLine">
        Website created by <a href="https://www.jamesjam.es" target="_blank" rel="noreferrer">James Bungay</a>
      </div>
      <div className="AttributionsLine">
        Icons made by <a href="https://www.flaticon.com/authors/vectors-market" title="Vectors Market" target="_blank" rel="noreferrer">Vectors Market</a> from <a href="https://www.flaticon.com/" title="Flaticon" target="_blank" rel="noreferrer">www.flaticon.com</a>
      </div>
      <div className="VATline">
        {data.vatText}
      </div>
    </footer>
  )
}

export default Footer
