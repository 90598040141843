import React from "react"
import { BurgerClick } from "../types"

import "./Burger.css"

export interface BurgerProps {
  handleClick: BurgerClick
}

export default function Burger({ handleClick }: BurgerProps) {
  return (
    <div
      role="button"
      tabIndex={0}
      className="Burger"
      onClick={handleClick}
      onKeyDown={handleClick}
    >
      <div className="Line" />
      <div className="Line" />
      <div className="Line" />
    </div>
  )
}
