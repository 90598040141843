import * as React from "react"

import Footer from "../Footer"

import "./Layout.css"
import "../Variables.css"

export const DEV_LINKS_LEFT = [
  { name: "HOME", link: "/" },
  { name: "ABOUT US", link: "/about" },
  { name: "OUR SERVICES", link: "/services" },
]

export const DEV_LINKS_RIGHT = [
  { name: "OUR CLIENTS", link: "/clients" },
  { name: "JOIN US", link: "/joinus" },
  { name: "CONTACT", link: "/contact" },
]

// interface LayoutProps {
//   children: ReactNodeArray
//   fluid?: boolean
// }

const Layout = ({ children }) => {
  return (
    <>
      <div className="Layout">
        <main>{children}</main>
      </div>
      <Footer links={[...DEV_LINKS_LEFT, ...DEV_LINKS_RIGHT]} />
    </>
  )
}

export default Layout