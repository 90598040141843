import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { navigate } from "gatsby"

import NavBar from "../NavBar"

import "./Header.css"

const Header = () => {
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "websiteHeader"}}) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                logo {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = query.allFile.edges[0].node.childMarkdownRemark.frontmatter

  const onClick = () => navigate("/")

  return (
    <header className="Header">
      <div className="Logo">
        <img src={data.logo.publicURL} alt="J-A-S Logo." onClick={() => onClick()} />
      </div>
      <div className="NavBarContainer">
        <NavBar />
      </div>
    </header>
  )
}

export default Header