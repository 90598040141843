import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Link as ScrollLink } from "react-scroll"

import Header from "../Header"

import "./HeroBox.css"

const HeroBox = ({ heading, text }) => {
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "websiteHeader"}}) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                backgroundImage {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = query.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <div className="HeroBoxContainer">
      <BackgroundImage
        Tag="section"
        fluid={data.backgroundImage.childImageSharp.fluid}
        className="HeroBoxBackground"
        backgroundColor="#040e18"
      />
      <div className="HeroBox">
        <div className="HeaderContainer">
          <Header />
        </div>
        <div className="HeroBoxContentContainer">
          <h1>{heading}</h1>
          <p>{text}</p>
          <ScrollLink
              to="LayoutInnerScrollTarget"
              smooth={true}
              duration={500}
          >
            <div className="ArrowContainer">
              <div class="arrow bounce"></div>
            </div>
          </ScrollLink>
        </div>
      </div>
      <div className="Filter" />
    </div>
  )
}

export default HeroBox
