import * as React from "react"

import "./LayoutInner.css"

// interface LayoutProps {
//   children: ReactNodeArray
//   fluid?: boolean
// }

const LayoutInner = ({ children }) => {
  return (
    <>
      <div className="LayoutInner" id="LayoutInnerScrollTarget">
        <main>{children}</main>
      </div>
    </>
  )
}

export default LayoutInner