import React from "react"
import { Link } from "gatsby"

import "./MobileNavigation.css"
import { BurgerClick, LinkType } from "../types"

export interface MobileNavigationProps {
  links: LinkType[]
  handleClickBack: BurgerClick
}

export default function MobileNavigation({
  links,
  handleClickBack,
}: MobileNavigationProps) {
  const Modal = () => (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <div
      role="button"
      tabIndex={0}
      onClick={handleClickBack}
      onKeyDown={handleClickBack}
      className="Modal"
    />
  )

  const Navigation = () => (
    <ul className="UL">
      {links.map(l => (
        <li key={l.link}>
          <Link
            className="LinkMobileNav"
            to={l.link}
            activeClassName="active"
          >
            {l.name}
          </Link>
        </li>
      ))}
    </ul>
  )

  return (
    <>
      <Navigation />
      <Modal />
    </>
  )
}
