import React, { useState } from "react"
import { Link } from "gatsby"

import Burger from "./components/Burger"
import MobileNavigation from "./components/MobileNavigation"

import "./NavBar.css"

export const DEV_LINKS_LEFT = [
  { name: "HOME", link: "/" },
  { name: "ABOUT US", link: "/about" },
  { name: "OUR SERVICES", link: "/services" },
]

export const DEV_LINKS_RIGHT = [
  { name: "OUR CLIENTS", link: "/clients" },
  { name: "JOIN US", link: "/joinus" },
  { name: "CONTACT", link: "/contact" },
]

const NavBar = () => {
  const [clicked, setClicked] = useState(false)

  const handleClick = (e) => {
    e.preventDefault()
    setClicked(true)
  }

  const handleClickBack = (e) => {
    e.preventDefault()
    setClicked(false)
  }

  return (
    <div className="TheNavBar">
      <div className="DesktopNavLinks">
        <Link className="LinkNavBar" to="/" activeClassName="active">Home</Link>
        <Link className="LinkNavBar" to="/about" activeClassName="active">About Us</Link>
        <Link className="LinkNavBar" to="/services" activeClassName="active">Our Services</Link>
        <Link className="LinkNavBar" to="/clients" activeClassName="active">Our Clients</Link>
        <Link className="LinkNavBar" to="/joinus" activeClassName="active">Join Us</Link>
        <Link className="LinkNavBar" to="/contact" activeClassName="active">Contact</Link>
      </div>
      <div className="MobileNavBurger">
        <Burger handleClick={handleClick} />
        {/* Show modal and slider with navigation */}
        {clicked && (
          <MobileNavigation
            links={[...DEV_LINKS_LEFT, ...DEV_LINKS_RIGHT]}
            handleClickBack={handleClickBack}
          />
        )}
      </div>
    </div>
  )
}

export default NavBar